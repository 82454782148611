import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { types as userTypes } from 'reduxModules/users/'
import fetchApi, { getUrl, fetchHelper } from '../utils/fetch'

//
// UTILS
// -----------------------------------------------------------------------------
export const getArtistUrl = slug => `/artists/${slug}/`

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  ARTISTS_INITIAL
  ARTISTS_FETCH_SUCCESS
  ARTISTS_REQUEST_FAILURE
  ARTISTS_SUCCESS
  ARTISTS_ERROR
  ARTIST_FETCH_SUCCESS
  ARTIST_REQUEST_FAILURE
  ARTISTS_FETCH_MORE_SUCCESS
  ARTISTS_FECTH_MORE_REQUEST_FAILURE
  ARTIST_CLEAR
  ARTISTS_RANDOM_FETCH_SUCCESS
  ARTISTS_RANDOM_REQUEST_FAILURE
`)

export const artistsStatus = {
  initial: types.ARTISTS_INITIAL,
  success: types.ARTISTS_SUCCESS,
  error: types.ARTISTS_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: artistsStatus.initial,
  list: [],
  detail: {
    favorited: null,
    facebookShareImages: [],
    networks: []
  }
}

export const successList = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload.results,
  next: action.payload.next,
  status: artistsStatus.success
})

export const successListMore = (state = INITIAL_STATE, action) => ({
  ...state,
  list: [...state.list, ...action.payload.results],
  next: action.payload.next,
  status: artistsStatus.success
})

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: action.payload,
  status: artistsStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: artistsStatus.error
})

export const successSetFavorite = (state = INITIAL_STATE, action) => {
  const objectType = action.payload.favoritedModel
  if (objectType === 'artist') {
    const objectId = action.payload.id
    return {
      ...state,
      detail: {
        ...state.detail,
        favorited: objectId
      },
      status: artistsStatus.success
    }
  }
  return state
}

export const successUnsetFavorite = (state = INITIAL_STATE, action) => {
  const objectType = action.payload.favoritedModel
  if (objectType === 'artist')
    return {
      ...state,
      detail: {
        ...state.detail,
        favorited: null
      },
      status: artistsStatus.success
    }
  return state
}

export const clearSuccess = (state = INITIAL_STATE) => ({
  ...state,
  detail: INITIAL_STATE.detail
})

export const HANDLERS = {
  [types.ARTISTS_FETCH_SUCCESS]: successList,
  [types.ARTISTS_REQUEST_FAILURE]: failure,
  [types.ARTIST_FETCH_SUCCESS]: successDetail,
  [types.ARTIST_REQUEST_FAILURE]: failure,
  [userTypes.USERS_FAVORITE_SET_SUCCESS]: successSetFavorite,
  [userTypes.USERS_FAVORITE_UNSET_SUCCESS]: successUnsetFavorite,
  [types.ARTISTS_FETCH_MORE_SUCCESS]: successListMore,
  [types.ARTISTS_FECTH_MORE_REQUEST_FAILURE]: failure,
  [types.ARTIST_CLEAR]: clearSuccess,
  [types.ARTISTS_RANDOM_FETCH_SUCCESS]: successList
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const artistsFetchSuccess = createAction(types.ARTISTS_FETCH_SUCCESS)
export const artistsFetchFailure = createAction(types.ARTISTS_REQUEST_FAILURE)
export const artistsFetch = filters => {
  const url = getUrl('/artists/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    artistsFetchSuccess,
    artistsFetchFailure
  )
}

export const artistFetchSuccess = createAction(types.ARTIST_FETCH_SUCCESS)
export const artistFetchFailure = createAction(types.ARTIST_REQUEST_FAILURE)
export const artistFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    getArtistUrl(slug),
    { method: 'GET' },
    artistFetchSuccess,
    artistFetchFailure
  )
}

export const artistsFetchMoreSuccess = createAction(
  types.ARTISTS_FETCH_MORE_SUCCESS
)
export const artistsFetchMoreFailure = createAction(
  types.ARTISTS_FECTH_MORE_REQUEST_FAILURE
)
export const artistsFetchMore = next =>
  fetchHelper(
    next,
    { method: 'GET' },
    artistsFetchMoreSuccess,
    artistsFetchMoreFailure
  )

export const clearArtist = createAction(types.ARTIST_CLEAR)

export const artistsRandomFetchSuccess = createAction(
  types.ARTISTS_RANDOM_FETCH_SUCCESS
)
export const artistsRandomFetchFailure = createAction(
  types.ARTISTS_RANDOM_REQUEST_FAILURE
)
export const artistsRandomFetch = filters => {
  const url = getUrl('/artists/random/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    artistsRandomFetchSuccess,
    artistsRandomFetchFailure
  )
}
