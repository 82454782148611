import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import { Field } from 'redux-form';
import { validators } from '../../../redux/modules/utils/forms';
import { CaptchaNotice } from '../CaptchaNotice';
import RenderedField from '../../generic/RenderedField';
import PasswordField from '../../generic/PasswordField';
import FacebookSignIn from '../../generic/FacebookSignIn/index';
import OpenAthensSignIn from '../../generic/OpenAthensSignIn/index';
import CaptchaStandalone from '../../CaptchaStandalone/_lazy';
export default function () {
    return React.createElement('div', {
        'id': 'LoginBox',
        'role': this.props.isMain ? 'main' : 'region'
    }, React.createElement('div', { 'className': 'sign-in-box auth-form-box' }, this.props.inCheckout ? React.createElement('button', {
        'className': 'toggle-box',
        'onClick': () => this.props.toggleAuth(true),
        'key': '770'
    }, '\n      Create your account\n    ') : null, React.createElement('form', {
        'id': 'SignInForm',
        'onSubmit': this.props.handleSubmit
    }, React.createElement(Field, {
        'name': 'email',
        'validate': [
            validators.required,
            validators.email
        ],
        'component': RenderedField,
        'type': 'email',
        'placeholder': 'Email',
        'autoFocus': this.props.autoFocus,
        'props': { ariaLabel: 'Email' }
    }), React.createElement(Field, {
        'name': 'password',
        'validate': [validators.required],
        'component': PasswordField,
        'placeholder': 'Password',
        'props': {
            ariaLabel: 'Password',
            iconLabel: 'Toggle password visibility'
        }
    }), React.createElement(CaptchaStandalone, {}), React.createElement('button', {
        'type': 'submit',
        'className': 'btn -wide -blue-2',
        'disabled': this.props.pristine || this.props.submitting || !this.props.recaptcha.token
    }, '\n        Login\n      ')), React.createElement('div', { 'className': 'divisor' }, 'or'), React.createElement(FacebookSignIn, {
        'text': 'Facebook Login',
        'location': this.props.location,
        'inCheckout': this.props.inCheckout
    }), React.createElement(OpenAthensSignIn, {}), !this.props.inCheckout ? React.createElement('div', {
        'className': 'auth-alt',
        'key': '1992'
    }, '\n      or ', React.createElement(Link, { 'to': '/users/signup' }, 'Register'), React.createElement('br', {}), ' to create an account\n    ') : null, React.createElement('div', { 'className': 'forgot-password' }, React.createElement(Link, { 'to': '/users/password/new' }, 'Forgot password?')), React.createElement(CaptchaNotice, { 'dark': true })));
}