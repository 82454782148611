/* global API_ROOT */
import { createAction } from 'redux-actions'
import _ from 'lodash'
import fetchApi, { fetchHelper } from '../utils/fetch'

export const INITIAL_STATE = {
  loading: 0,
  showBannerSubscription: false,
  width: 0,
  height: 0,
  hideNav: false,
  hideInnerNav: false,
  confirmation: {},
  isMobile: false,
  showMobileMenu: false,
  pendingBackScroll: {
    tutorials: {
      element: 0,
      isPending: false
    },
    posts: {
      element: 0,
      isPending: false
    },
    artists: {
      element: 0,
      isPending: false
    }
  },
  // A valid promotional code applied globally
  globalPromo: {
    codename: '',
    discount: 0,
    discountType: '',
    plans: {
      '3': { id: 1, name: '', price: 0, discountAmount: 0, priceDiscount: 0 },
      '4': { id: 2, name: '', price: 0, discountAmount: 0, priceDiscount: 0 },
      '7': { id: 3, name: '', price: 0, discountAmount: 0, priceDiscount: 0 }
    },
    headlineTitle: '',
    subHeadline1: '',
    subHeadline2: '',
    isLoading: true
  },
  // Promotion running on the website
  promotion: {
    title: '',
    description: '',
    startingDate: '',
    endingDate: '',
    couponPromoCode: {
      codename: ''
    }
  },
  isFullscreen: false,
  utm: null,
  scrollPercentage: 0,
  recaptcha: {
    token: undefined,
    version: 0,
    cleared: false
  }
}

export default function app(state = INITIAL_STATE, action) {
  // Loading Reducer.
  // General for all API calls.
  // -------------------------------------------------------------------------
  if (!Object.keys(action).length) return state

  if (action.type === 'FETCHING')
    return { ...state, loading: state.loading + 1 }
  if (
    _.endsWith(action.type, '_SUCCESS') ||
    _.endsWith(action.type, '_FAILURE')
  ) {
    const newLoading = state.loading - 1
    return { ...state, loading: newLoading < 0 ? 0 : newLoading }
  }

  if (action.type === 'UPDATE_SCREEN_DIMENSIONS')
    return {
      ...state,
      width: action.payload.width,
      height: action.payload.height
    }

  if (action.type === 'HIDE_GLOBAL_NAV') return { ...state, hideNav: true }

  if (action.type === 'SHOW_GLOBAL_NAV') return { ...state, hideNav: false }

  if (action.type === 'HIDE_INNER_NAV') return { ...state, hideInnerNav: true }

  if (action.type === 'SHOW_INNER_NAV') return { ...state, hideInnerNav: false }

  if (action.type === 'INIT_CONFIRMATION')
    return { ...state, confirmation: action.payload }

  if (action.type === 'RESOLVE_CONFIRMATION')
    return { ...state, confirmation: {} }

  if (action.type === 'SET_IS_MOBILE') return { ...state, isMobile: true }

  if (action.type === 'UNSET_IS_MOBILE') return { ...state, isMobile: false }

  if (action.type === 'TOGGLE_MOBILE_MENU')
    return { ...state, showMobileMenu: !state.showMobileMenu }

  if (action.type === 'SET_PENDING_SCROLL') {
    const pendingBackScroll = {
      ...state.pendingBackScroll
    }
    pendingBackScroll[action.payload.group] = {
      ...state.pendingBackScroll[action.payload.group],
      isPending: true
    }
    return {
      ...state,
      pendingBackScroll
    }
  }

  if (action.type === 'UNSET_PENDING_SCROLL') {
    const pendingBackScroll = {
      ...state.pendingBackScroll
    }
    pendingBackScroll[action.payload.group] = {
      ...state.pendingBackScroll[action.payload.group],
      isPending: false
    }
    return {
      ...state,
      pendingBackScroll
    }
  }

  if (action.type === 'SET_ELEMENT_TO_SCROLL') {
    const pendingBackScroll = {
      ...state.pendingBackScroll
    }
    pendingBackScroll[action.payload.group] = {
      ...state.pendingBackScroll[action.payload.group],
      element: action.payload
    }
    return {
      ...state,
      pendingBackScroll
    }
  }

  if (action.type === 'SET_FULLSCREEN') return { ...state, isFullscreen: true }

  if (action.type === 'UNSET_FULLSCREEN')
    return { ...state, isFullscreen: false }

  if (action.type === 'SET_UTM_DATA') return { ...state, utm: action.payload }

  // Global Promo
  if (action.type === 'VALIDATE_GLOBAL_PROMO_OK') {
    const newLoading = state.loading - 1

    return {
      ...state,
      status: 'VALIDATE_GLOBAL_PROMO_OK',
      globalPromo: {
        ...state.globalPromo,
        ...action.payload,
        isLoading: false
      },
      loading: newLoading < 0 ? 0 : newLoading
    }
  }

  if (action.type === 'VALIDATE_GLOBAL_PROMO_FAIL') {
    const newLoading = state.loading - 1

    return {
      ...state,
      status: 'VALIDATE_GLOBAL_PROMO_FAIL',
      loading: newLoading < 0 ? 0 : newLoading,
      globalPromo: { ...state.globalPromo, isLoading: false }
    }
  }

  if (action.type === 'UPDATE_SCROLL_PERCENTAGE')
    return {
      ...state,
      scrollPercentage: action.payload
    }

  if (action.type === 'GET_CURRENT_PROMOTION_OK') {
    const newLoading = state.loading - 1
    return {
      ...state,
      promotion: action.payload,
      loading: newLoading < 0 ? 0 : newLoading
    }
  }

  if (action.type === 'GET_CURRENT_PROMOTION_FAIL') {
    const newLoading = state.loading - 1
    return {
      ...state,
      loading: newLoading < 0 ? 0 : newLoading
    }
  }

  if (action.type === 'SET_UTM_DATA') return { ...state, utm: action.payload }

  if (action.type === 'SET_RECAPTCHA_TOKEN')
    return {
      ...state,
      recaptcha: { ...state.recaptcha, ...action.payload, cleared: false }
    }

  if (action.type === 'CLEAR_RECAPTCHA_TOKEN')
    return {
      ...state,
      recaptcha: { ...INITIAL_STATE.recaptcha, cleared: true }
    }

  if (action.type === 'SET_STANDARD_SUBSCRIPTION_PLAN')
    return {
      ...state,
      globalPromo: { ...state.globalPromo, isLoading: false }
    }

  return state
}

export const updateDimensions = createAction('UPDATE_SCREEN_DIMENSIONS')

export const hideNavigation = createAction('HIDE_GLOBAL_NAV')
export const showNavigation = createAction('SHOW_GLOBAL_NAV')

export const hideInnerNavigation = createAction('HIDE_INNER_NAV')
export const showInnerNavigation = createAction('SHOW_INNER_NAV')

export const setIsMobile = createAction('SET_IS_MOBILE')
export const unsetIsMobile = createAction('UNSET_IS_MOBILE')

export const toggleMobileMenu = createAction('TOGGLE_MOBILE_MENU')

export const setPendingScroll = createAction('SET_PENDING_SCROLL')
export const unsetPendingScroll = createAction('UNSET_PENDING_SCROLL')
export const setElementToScroll = createAction('SET_ELEMENT_TO_SCROLL')

export const setFullscreen = createAction('SET_FULLSCREEN')
export const unsetFullscreen = createAction('UNSET_FULLSCREEN')

export const setUTM = createAction('SET_UTM_DATA')
export const sendUTMSuccess = createAction('SET_UTM_SUCCESS')
export const sendUTMFailure = createAction('SET_UTM_FAILURE')
export const sendUTM = fetchHelper(
  `${API_ROOT}/utm/`,
  { method: 'POST' },
  sendUTMSuccess,
  sendUTMFailure
)

// Global Promo

export const validateGlobalPromoCodeSuccess = createAction(
  'VALIDATE_GLOBAL_PROMO_OK'
)
export const validateGlobalPromoCodeFailure = createAction(
  'VALIDATE_GLOBAL_PROMO_FAIL'
)

export const validateGlobalPromoCode = (code, plans) => {
  const encodedPlans = encodeURIComponent(JSON.stringify(plans))
  return fetchApi(
    `/coupons/${encodeURI(code)}/?plans=${encodedPlans}`,
    { method: 'GET' },
    validateGlobalPromoCodeSuccess,
    validateGlobalPromoCodeFailure
  )
}

export const updateScrollPercentage = createAction('UPDATE_SCROLL_PERCENTAGE')

export const getCurrentPromotionSuccess = createAction(
  'GET_CURRENT_PROMOTION_OK'
)
export const getCurrentPromotionFailure = createAction(
  'GET_CURRENT_PROMOTION_FAIL'
)

export const getCurrentPromotion = fetchApi(
  '/get-current-promotion',
  { method: 'GET' },
  getCurrentPromotionSuccess,
  getCurrentPromotionFailure
)

export const displayStandardSubscriptionPlans = createAction(
  'SET_STANDARD_SUBSCRIPTION_PLAN'
)

export const setRecaptchaToken = createAction('SET_RECAPTCHA_TOKEN')
export const clearRecaptchaToken = createAction('CLEAR_RECAPTCHA_TOKEN')
